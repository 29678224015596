import React from 'react'
import * as AntdComponents from "antd"
import logo from '../assets/logo.png'

const Header = () => {
    return (
        <div style={{}} className="header">
            <div className="custom-container flex flex-col items-center justify-between py-1 lg:h-full lg:flex-row lg:py-0">
                <div className="">
                    <AntdComponents.Image preview={false} src={logo} alt="logo" height={40} width={'auto'} />
                </div>
                <div className="flex items-center gap-5">
                    <a className='text-title20 text-Pink hover:text-Purple' href={'https://admin.vaultpd.com/privacy_for_app'} target='_'>Privacy Policy</a>
                </div>
            </div>
        </div>
    )
}

export default Header
