import React from 'react'
import secure from '../assets/secure.png'
import * as AntdComponents from "antd"
import checklist from "../assets/Checklists.png"
import emergency from "../assets/Emergency.png"
import hurricanes from "../assets/Hurricanes.png"
import ThreeDotsLoader from './ThreeDotsLoader'

const Dummy = () => {
    return (
        <>
            <div className='py-10'>
                <div className="flex justify-center ">
                    <h1 className='text-Pink'>Coming Soon</h1>
                    <ThreeDotsLoader/>
                </div>
                <div className="custom-container mt-10">
                    <AntdComponents.Row gutter={[16, 16]}>
                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} className='text-center'>
                            <AntdComponents.Image src={checklist} preview={false} height={450} />
                        </AntdComponents.Col>
                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} className='text-center'>
                            <AntdComponents.Image src={emergency} preview={false} height={450} />
                        </AntdComponents.Col>
                        <AntdComponents.Col xs={24} sm={24} md={12} lg={8} xl={8} className='text-center'>
                            <AntdComponents.Image src={hurricanes} preview={false} height={450} />
                        </AntdComponents.Col>
                    </AntdComponents.Row>
                    <div className="mt-9 text-center">
                        <AntdComponents.Image preview={false} src={secure} alt="logo" width={'auto'} />
                        <h2>Safely store what matters to you</h2>
                        <h4>Your digital vault for  personal documents</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dummy
